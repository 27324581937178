body {
  background-color: #282c34;
  color: white;
}

.nav {
  padding: 20px;  
  color: black;
}

.logo {
  height: 150px;
  padding: 10px;
}

.logo2 {
  height: 80px;
  padding: 10px;
}

.imgGroup {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 70px;
}

.subTitle {
  font-size: 40px;
}


/* Breakdown */

.breakdown {
  /* background-color: #282c34; */
  text-align: center;
}

/* Footer: */

.footer {
  background-color: rgb(27, 27, 27);
  color: white;
  padding: 10px;
  width:100%;
  position: relative;
  bottom: 0;
}

@media only screen and (max-width: 600px) {
  .footer {
    background-color: rgb(27, 27, 27);
    color: white;
    padding: 10px;
    width:100%;
    position: relative;
    bottom: 0;
  }
}